<template>
  <button
    class="atc-secondary-button"
    :class="{ 'inline-atc-secondary-button': !isFullScreen }"
    @click="goToProductPage()"
    :style="`border: 2px solid ${primaryColor} !important;`"
  >
    <info-icon
      :class="{ 'inline-info-icon': !isFullScreen }"
      :fill="secondaryColor"
      ref="icon"
    ></info-icon>
    <span
      class="atc-add-button__text"
      :style="`color: ${secondaryColor} !important`"
    >
      {{ $t("player.atcSecondary.goToProduct") }}
    </span>
  </button>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    InfoIcon: () => import("@/assets/information-circle.svg"),
  },
  props: {
    colors: {
      type: Object,
      default: () => {
        return { primaryColor: "#000000", secondaryColor: "#000000" };
      },
    },
  },

  watch: {
    secondaryColor() {
      this.$refs.icon.setAttribute("fill", this.secondaryColor);
    },
  },

  computed: {
    ...mapGetters("playerCampaign", [
      "isFullScreen",
    ]),
    primaryColor() {
      if (this.colors.primaryColor === this.colors.secondaryColor)
        return "#000000";
      return this.colors.primaryColor;
    },
    secondaryColor() {
      if (this.colors.primaryColor === this.colors.secondaryColor)
        return "#000000";
      else if (
        this.colors.secondaryColor === "#FFFFFF" ||
        this.colors.secondaryColor === "#FFFFFFFF"
      ) {
        return this.primaryColor;
      }
      return this.colors.secondaryColor;
    },
  },

  methods: {
    goToProductPage() {
      this.$emit("go-to-product-page", this.productId);
    },
  },
};
</script>

<style lang="scss" scoped>
.atc-secondary-button {
  font-family: "Montserrat", sans-serif;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  padding: 6px 0;
  transition: 0.4s ease box-shadow, 0.4s ease transform;
  border-radius: 3px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  background: white;

  &:hover {
    box-shadow: none;
    transform: translateX(2px) translateY(2px);
  }

  &__text {
    padding-left: 14px;
  }
  &:disabled {
    background: grey;
    transform: translateX(2px) translateY(2px);
  }
}

.is-in-preview {
  width: 150px;
}

.inline-atc-secondary-button {
  font-size: 14px;
}
.inline-info-icon {
  transform: scale(80%);
}
</style>
