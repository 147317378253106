<template>
  <div class="manucurist-container">
    <p class="subtitle">
      {{
        `${$t("player.manucurist.pick")}${
          manucuristProduct.colorsToSelect
        } ${$t("player.manucurist.color")}:`
      }}
    </p>
    <div class="picker-container">
      <div
        class="input-container"
        v-for="{ imgUrl, id, text } in getPickerData"
        :key="id"
      >
        <p v-if="selectedColor.includes(id)" class="indication-selector">
          {{ returnIndexOfSelectedColor(id) }}
        </p>
        <input
          class="input-picker"
          type="checkbox"
          :id="id + uuid"
          :name="id"
          :value="id"
          :ref="`input${uuid}`"
          :disabled="disableSelection(id)"
          @change="handleChange()"
        />
        <label
          class="color-bubble"
          :for="id + uuid"
          :style="`background-image: url('${imgUrl}'); `"
          v-tooltip="{
            text: text,
          }"
        ></label>
      </div>
    </div>
  </div>
</template>

<script>
import pickerData from "./picker-data.json";
export default {
  name: "ManucuristPicker",
  props: {
    manucuristProduct: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      uuid: "",
      pickerData,
      selectedColor: [],
    };
  },
  created() {
    this.uuid = this.$uuid.v4();
  },
  computed: {
    getPickerData() {
      return this.pickerData;
    },
  },
  methods: {
    disableSelection(id) {
      return (
        this.selectedColor.length >= this.manucuristProduct.colorsToSelect &&
        !this.selectedColor.includes(id)
      );
    },

    returnIndexOfSelectedColor(id) {
      return this.selectedColor.findIndex((item) => item === id) + 1;
    },

    handleChange() {
      this.selectedColor = this.$refs[`input${this.uuid}`].filter(
        (input) => input.checked
      );
      this.selectedColor = this.selectedColor.map((item) => item.value);
      let dataToSend = [];
      if (this.selectedColor.length === 0) {
        this.$emit("selected-color", {});
        return;
      } else {
        this.selectedColor.forEach((item, index) => {
          dataToSend.push({
            [`${this.manucuristProduct.propertyName} ${index + 1}`]: item,
          });
        });
        dataToSend = Object.assign(...dataToSend);
        this.$emit("selected-color", {
          properties: { properties: { ...dataToSend } },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "./manucurist.scss";
</style>

<style lang="scss" scoped>
.manucurist-container {
  margin-bottom: 32px;
}
.subtitle {
  font-weight: bold;
  margin-bottom: 16px;
}

.picker-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(45px, 1fr));
  gap: 8px;
}

.input-container {
  position: relative;
}

.input-picker {
  display: none;
}

.color-bubble {
  display: block;
  text-indent: -9999px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 45px;
  height: 45px;
  transition: box-shadow 0.2s ease;
  border-radius: 100%;
  cursor: pointer;
}
.input-picker:checked + .color-bubble {
  box-shadow: 0 0 0 2px #004e42;
  outline: 0.5px solid white;
}

.indication-selector {
  position: absolute;
  right: -5px;
  top: -5px;
  width: 20px;
  height: 20px;
  background-color: #004e42;
  color: #fff;
  text-align: center;
  line-height: 20px;
  z-index: 2;
  border-radius: 20px;
}
</style>